<template>
  <div class="login-page">
    <div class="logo">
      <img src="@/assets/img/logo.png" alt="Logo" width="150">
    </div>
    <div class="login-page-inner">
      <div class="login-form-card">


        <h1>Login</h1>
        <svg class="block mx-auto mb-6" xmlns="http://www.w3.org/2000/svg" width="100" height="2" viewBox="0 0 100 2">
          <path fill="#D8E3EC" d="M0 0h100v2H0z"></path>
        </svg>

        <a-form class="login-form" ref="formRef" :model="formState" :rules="rules">
          <a-form-item has-feedback name="email">
            <a-input v-model:value="formState.email" placeholder="Email" size="large" @keyup.enter="handleSubmit">
              <template #prefix>
                <MailOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item has-feedback name="password">
            <a-input v-model:value="formState.password" type="password" placeholder="Password" size="large"
              @keyup.enter="handleSubmit">
              <template #prefix>
                <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-button block size="large" :loading="isLoading" type="primary" @click.prevent="handleSubmit">
              Login
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="actions">
        Don't have an account? <router-link to="/register">Click here to sign up.</router-link>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons-vue';
import { reactive, toRaw } from 'vue';

export default {
  name: 'Home',
  components: { UserOutlined, LockOutlined, MailOutlined },
  data() {
    return {
      formState: reactive({
        email: '',
        password: '',
      }),
      rules: {
        'email': [
          { required: true, message: 'Please input your email' },
          { type: 'email', message: 'Please enter a valid email address' }
        ],
        'password': [
          { required: true, message: 'Please input your password' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('auth', {
      isLoading: 'isLoading'
    })
  },
  methods: {
    ...mapActions('auth', {
      attemptLogin: 'attemptLogin'
    }),

    handleSubmit() {
      this.$refs['formRef']
        .validate()
        .then(() => {
          let values = toRaw(this.formState);
          this.attemptLogin({
            email: values.email,
            password: values.password,
            to: this.$route.query.to ? this.$route.query.to : '/'
          });
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  }
}
</script>
  
<style lang="scss">
.login-page {
  background: rgb(31, 27, 45);
  width: 100%;
  height: 100%;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    margin-bottom: 10px;
  }

  .login-form-card {
    margin-top: 25px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
    max-width: 25rem;
    width: 25rem;
    padding: 2rem 2rem 0.4rem 2rem;
    background: #fff;
    border-radius: .5rem;

    img {
      margin-bottom: 20px;
    }

    .login-form {
      margin-top: 30px;
    }

    .login-form-button {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .actions {
    color: #fff;
    margin-top: 20px;
  }
}
</style>
  