import axios from 'axios';

let _ = require('lodash');
import router from "../../router";
import {message} from 'ant-design-vue';

export const state = {
    isLoading: false,
    conversations: [
        {
            id: 1,
            title: 'Conversation 1'
        },
        {
            id: 2,
            title: 'Conversation 2'
        },
        {
            id: 3,
            title: 'Conversation 3'
        }
    ],
    selectedConversation: null,
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true;
    },

    STOP_LOADING(state) {
        state.isLoading = false;
    },

    SET_CONVERSATIONS(state, conversations) {
        state.conversations = conversations;
    },

    SET_SELECTED_CONVERSATION(state, conversation) {
        state.selectedConversation = conversation;
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    conversations: (state) => {
        return state.conversations;
    },

    selectedConversation: (state) => {
        return state.selectedConversation;
    }
};

export const actions = {
    attemptLogin({commit}, params) {
        commit('START_LOADING');
        axios.post(window.API_TOKEN_URL, {
            'email': params.email,
            'password': params.password,
            'device_name': 'Browser login',
            'user_type': 'admin'
        }).then(r => {
            commit('STOP_LOADING');
            commit('SET_API_TOKEN', r.data.token);
            commit('SET_USER', r.data.user);
            router.push(params.to ? params.to : '/');
        }).catch(e => {
            commit('STOP_LOADING');
            message.error('Invalid email/password combination');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        });
    },

    logout({commit}) {
        commit('SET_API_TOKEN', null);
        commit('SET_USER', null);
        router.push('/login');
        message.success('Logged out successfully');
    }
};
