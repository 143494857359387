<template>
    <div class="chat">
        <!-- Loading -->
        <div v-if="isLoading" class="text-center loading-wrapper">
            <a-spin />
        </div>
        <!-- / Loading -->

        <!-- No conversation -->
        <div v-if="!isLoading && !conversation" class="no-chat-wrapper">
            <a-alert type="error" message="Error loading conversation, please try again." />
        </div>
        <!-- / No conversation -->

        <!-- Conversation loaded -->
        <div v-if="!isLoading && conversation" class="chat-inner">
            <div class="top timeline">

                <div class="chat-entry-wrapper" v-for="(message, i) in messages" :key="`message-${i}`"
                    :class="{ 'mine': message.isMine }">
                    <div class="chat-entry">
                        <div class="message">
                            <div v-html="message.text"></div>

                            <img class="image" :src="message.attachment.url"
                                v-if="message.attachment && message.attachment.type === 'image'" />
                            <video controls class="image" height="160"
                                v-if="message.attachment && message.attachment.type === 'video'" autoplay="">
                                <source :src="message.attachment.url" type="video/mp4">
                            </video>
                            <audio controls class="image" v-if="message.attachment && message.attachment.type === 'audio'"
                                autoplay="">
                                <source :src="message.attachment.url" type="audio/mp3">
                            </audio>
                        </div>

                        <div
                            v-if="message.original && message.original.type === 'actions' && message.original.actions && message.original.actions.length">
                            <a-button type="primary" style="margin-right: 6px; margin-top: 10px;" class="btn"
                                v-for="(action, i) in message.original.actions" :key="`action-${i}-${j}`"
                                @click="performAction(action.value, message.original)">
                                <img v-if="action.image_url" :src="action.image_url" style="max-height: 25px" />
                                {{ action.text }}
                            </a-button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="bottom">
                <div class="bottom-toolbar-wrapper">
                    <div class="send-message-wrapper">
                        <div class="left">
                            <a-input @keydown.enter="sendMessage" placeholder="Message..." v-model:value="messageToSend"
                                size="large"></a-input>
                        </div>
                        <div class="right">
                            <a-button :disabled="!canSendMessage" @click.prevent="sendMessage" size="large">Send
                                Message</a-button>

                            <a-button @click.prevent="showQuickMessagesModal" size="large" class="quick-messages-button">
                                <template #icon>
                                    <ThunderboltOutlined />
                                </template>
                            </a-button>
                            <a-modal v-model:visible="quickMessagesModalVisible" title="Quick Message" :footer="false">
                                <div class="buttons-wrapper">
                                    <div class="quick-message-option" v-for="(option, i) in quickMessages" :key="i">
                                        <a-button @click.prevent="sendQuickMessage(option)" block size="large">
                                            {{ option }}
                                        </a-button>
                                    </div>
                                </div>
                            </a-modal>

                            <input type="file" class="chat-attachment" id="attachment" />
                            <!-- <div>
                                <input type="file" class="ChatAttachment" id="attachment" value="Attachment" />
                                <label for="attachment">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                                        <path
                                            d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                                        </path>
                                    </svg> <span>Attachment</span>
                                </label>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Conversation loaded -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import { ThunderboltOutlined } from '@ant-design/icons-vue';

export default {
    data() {
        return {
            isLoading: false,
            conversation: {},
            messages: [

            ],
            messageToSend: '',

            quickMessagesModalVisible: false,
            quickMessages: [
                'Onboard client', 'View onboarding status', 'View matters'
            ]
        }
    },

    created() {
        this.reset();
    },

    components: { ThunderboltOutlined },

    computed: {
        ...mapGetters('auth', {
            user: 'user'
        }),

        userId() {
            return this.user.id;
        },

        apiEndpoint() {
            return window.CONVERSATION_URL;
        },

        canSendMessage() {
            return this.messageToSend.trim().length > 0;
        }
    },

    // mounted() {
    //     let control = document.getElementById("attachment");
    //     control.addEventListener("change", () => {
    //         const file = control.files[0];
    //         if (file) {
    //             let type = file.type.split('/')[0];
    //             const reader = new FileReader();

    //             if (type !== 'video' && type !== 'audio' && type !== 'image') {
    //                 type = 'file';
    //             }

    //             reader.addEventListener("load", () => {
    //                 this._addMessage(null, {
    //                     type,
    //                     url: reader.result
    //                 }, true);

    //                 this.callAPI(null, false, type);

    //                 control.value = '';
    //             }, false);

    //             reader.readAsDataURL(file);
    //         }

    //     }, false);
    // },

    methods: {
        reset() {
            if (this.user && this.user.name == 'Harvey Coplestone') {
                this.messages.push(
                    {
                        text: 'Welcome back Harvey, how can I help?',
                    }
                );
            } else {
                this.messages.push(
                    {
                        text: 'Hello, how can I help you?',
                    }
                );
            }
        },

        callAPI(text, interactive = false, attachment = null, callback) {
            let data = new FormData();
            const postData = {
                driver: 'web',
                userId: this.userId,
                message: text,
                attachment,
                interactive,
                attachment_data: document.getElementById('attachment').files[0]
            };

            Object.keys(postData).forEach(key => data.append(key, postData[key]));

            axios.post(this.apiEndpoint, data).then(response => {
                const messages = response.data.messages || [];
                messages.forEach(msg => {
                    this._addMessage(msg.text, msg.attachment, false, msg);
                });
                if (callback) {
                    callback(response.data);
                }
            });
        },

        performAction(value, message) {
            this.callAPI(value, true, null, (response) => {
                message.actions = null;
            });
        },

        _addMessage(text, attachment, isMine, original = {}) {
            this.messages.push({
                'isMine': isMine,
                'user': isMine ? '👨' : '🤖',
                'text': text,
                'original': original,
                'attachment': attachment || {},
            });

            this.$nextTick(() => {
                let chat = document.querySelector('.chat .top');
                chat.scrollTop = chat.scrollHeight;
            });
        },

        sendMessage() {
            if (!this.canSendMessage) {
                return false;
            }

            let messageText = this.messageToSend.trim();
            this.messageToSend = '';
            if (messageText === 'clear') {
                this.messages = [];
                this.reset();
                return;
            }

            this._addMessage(messageText, null, true);
            this.callAPI(messageText);
        },

        showQuickMessagesModal() {
            this.quickMessagesModalVisible = true;
        },

        hideQuickMessageModal() {
            this.quickMessagesModalVisible = false;
        },

        sendQuickMessage(quickMessage) {
            this.hideQuickMessageModal();
            this.messageToSend = quickMessage;
            this.sendMessage();
        },
    }
}
</script>

<style scoped lang="scss">
.chat {
    height: 100%;
    width: 100%;

    .chat-inner {
        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: column;

        .top {
            flex-grow: 1;
            overflow-y: scroll;

            .chat-entry-wrapper {
                text-align: left;
                // margin-top: 15px;
                margin-bottom: 15px;
                display: flex;
                width: 100%;

                &.mine {
                    justify-content: flex-end;

                    .chat-entry {
                        background: #5155ea;
                        color: #fff;
                    }
                }

                .chat-entry {
                    // max-width: 45%;
                    max-width: 48%;
                    width: 100%;
                    background: #e0e0e0;
                    border-radius: 7px;
                    padding: 13px 15px;
                    white-space: pre-wrap !important;

                    p:last-child {
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }

                    .image {
                        max-width: 100%;
                        width: 100%;
                        margin-top: 15px;
                    }
                }
            }
        }

        .bottom {
            flex-shrink: 1;
            padding-top: 20px;

            .chat-attachment {
                display: none;
            }

            .send-message-wrapper {
                background: #fff;
                padding: 10px;
                border-radius: 10px;
                display: flex;
                box-shadow: 0 0 8px rgba(0, 0, 0, .04);

                .ant-input {
                    border: 0;

                    &:focus {
                        outline: none !important;
                        box-shadow: none !important;
                    }
                }

                .left {
                    flex-grow: 1;
                }

                .right {
                    flex-shrink: 1;
                    padding-left: 10px;

                    .quick-messages-button {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

.quick-message-option {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}
</style>