import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import '@/assets/enhanceable-theme/index.scss';

window.moment = require('moment');

window.API_COMMON = 'http://localhost:3000/api';
window.API_BASE = 'http://localhost:3000/api';
window.CONVERSATION_URL = 'http://localhost:3000/botman';
window.API_TOKEN_URL = 'http://localhost:3000/api/token';
window.CLIO_CALLBACK_URL = 'http://127.0.0.1:8080/clio/callback';

// if (window.location.hostname == 'admin.syndesi.network') {
//     window.API_COMMON = 'https://api.syndesi.network/api';
//     window.API_BASE = 'https://api.syndesi.network/api/admin';
//     window.API_TOKEN_URL = 'https://api.syndesi.network/api/admin/token'; 
// }

if (window.location.hostname == 'emmi.enhanceable.io') {
    window.API_COMMON = 'https://emmi-api.enhanceable.io/api';
    window.API_BASE = 'https://emmi-api.enhanceable.io/api';
    window.CONVERSATION_URL = 'https://emmi-api.enhanceable.io/botman';
    window.API_TOKEN_URL = 'https://emmi-api.enhanceable.io/api/token';
    window.CLIO_CALLBACK_URL = 'https://emmi.enhanceable.io/clio/callback';
}

// Axios Interceptor
axios.interceptors.request.use(
    config => {
        const token = store.getters['auth/apiToken'];
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

// Axios Interceptor
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (
        error.response.status === 401
        && error.response.data.message === 'OTP code required'
        && router.currentRoute.value.path !== '/2fa'
    ) {
        router.push('/2fa');
    } else if (
        error.response.status === 401
    ) {
        router.push('/');
        store.dispatch('auth/logout');
    }
    return Promise.reject(error);
});


createApp(App).use(Antd).use(store).use(router).mount('#app')
