<template>
  <div id="app">
    <a-layout id="primary-layout" v-if="loggedIn&&!isOnboarding">
      <a-layout-header class="topnav">
        <div class="logo">
          <img @click.prevent="navigateToHome" src="@/assets/img/logo.png" height="40" alt="" />
        </div>
        <div class="actions-right" v-if="user">
          <a-dropdown :trigger="['click']">
            <a class="ant-dropdown-link" @click.prevent>
              {{ user.name }}
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <router-link to="/settings">Settings</router-link>
                </a-menu-item>
                <a-menu-item>
                  <a href="#" @click.prevent="logout">Logout</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout>
        <a-layout-sider
        v-if="shouldShowSider"
          class="sidenav"
          :collapsible="true"
          width="230"
          theme="light"
          style="background: #fff"
          @oldmouseover="handleMouseoverNavbarEvent"
          @oldmouseout="handleMouseoutNavbarEvent"
          v-model:collapsed="collapsed"
        >
          <a-menu
            @click="handleMenuItemClicked"
            :selected-keys="[selectedMenuKey]"
            mode="inline"
            :style="{ height: '100%', borderRight: 0 }"
          >
            <a-menu-item key="">
              <plus-outlined />
              <span>New Conversation</span>
            </a-menu-item>
            <!-- <a-menu-item v-for="conversation in conversations" :key="`conversations/${conversation.id}`">
              <message-outlined />
              <span>{{ conversation.title }}</span>
            </a-menu-item> -->
          </a-menu>
        </a-layout-sider>
        <a-layout style="padding: 25px" class="primary-content">
          <a-layout-content>
            <router-view />
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
    <a-layout id="primary-layout" v-if="isOnboarding">
      <a-layout-header class="topnav">
        <div class="logo">
          <img src="@/assets/img/logo.png" height="40" alt="" />
        </div>
      </a-layout-header>
      <a-layout>
        <a-layout style="padding: 25px" class="primary-content">
          <a-layout-content>
            <router-view />
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
    <router-view v-if="!(loggedIn||isOnboarding)" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  UserOutlined,
  // DashboardOutlined,
  DownOutlined,
  CalculatorOutlined,
  UnorderedListOutlined,
  SettingOutlined,
  HeartOutlined,
  MessageOutlined,
  PlusOutlined
} from "@ant-design/icons-vue";

export default {
  components: {
    UserOutlined,
    // DashboardOutlined,
    DownOutlined,
    CalculatorOutlined,
    UnorderedListOutlined,
    SettingOutlined,
    HeartOutlined,
    MessageOutlined,
    PlusOutlined
  },
  data() {
    return {
      hoveringOnNavbar: false,
      selectedMenuKey: [],
      collapsed: false
    };
  },
  computed: {
    ...mapGetters("auth", {
      loggedIn: "loggedIn",
      user: "user",
    }),

    ...mapGetters("conversations", {
      conversations: "conversations"
    }),

    shouldShowSider() {
      let isOn2fa = this.$route.name == '2FA';
      let isOnOnboarding = this.$route.name == 'onboarding';
      let isOnSettings = this.$route.name == 'settings';
      let isOnClioCallback = this.$route.name == 'clio-callback';
      return !isOn2fa && !isOnOnboarding && !isOnSettings && !isOnClioCallback;
    },

    isOnboarding() {
      return this.$route.name == 'onboarding';
    }

    // collapsed() {
    //   if (this.hoveringOnNavbar) {
    //     return false;
    //   }

    //   return true;
    // },
  },
  methods: {
    ...mapActions("auth", {
      logout: "logout",
    }),

    handleMouseoverNavbarEvent() {
      this.hoveringOnNavbar = true;
    },

    handleMouseoutNavbarEvent() {
      this.hoveringOnNavbar = false;
    },

    handleMenuItemClicked(e) {
      let navigateTo = e.key;
      this.$router.push("/" + navigateTo);
    },

    navigateToHome() {
      this.$router.push("/");
    }
  },
  watch: {
    $route(to) {
      this.selectedMenuKey = to.path.split("/")[1];
    },
  },
};
</script>

<style lang="scss">
html,
body,
#app,
#primary-layout {
  height: 100%;
  overflow: hidden;
}

.primary-content {
  height: 100%;
  overflow-y: scroll;
}

.topnav {
  height: 57px !important;
  line-height: 57px !important;
  padding: 0 25px !important;

  .ant-menu {
    justify-content: flex-end;

    .ant-menu-item {
      &:hover,
      &.ant-menu-item-selected {
        background: none !important;
      }
    }
  }

  .logo {
    float: left;
    display: flex;
    align-items: center;
    height: 100%;

    img {
      height: 80%;
      cursor: pointer;
    }
  }

  .actions-right {
    float: right;

    a {
      color: #fff;
    }
  }
}

.sidenav {
  .ant-menu {
    //border-right: none;
    padding-top: 6px;
  }

  .ant-menu-inline-collapsed {
    .ant-menu-item .anticon {
      position: absolute;
      left: 0;
      right: 0;
      top: 5px;
    }
  }

  .ant-menu-item {
    .anticon {
      font-size: 19px !important;
    }

    border-radius: 8px !important;
    width: 93% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    border-right: none !important;
    height: 43px !important;
    line-height: 43px !important;
    font-weight: 400;

    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 15px !important;

    .ant-badge {
      margin-left: 8px !important;
      position: absolute !important;
      right: 9px !important;
      top: 14px !important;
    }

    &:hover,
    &.ant-menu-item-selected {
      background: rgba(81, 85, 234, 0.1) !important;
    }

    &.ant-menu-item-selected::after {
      opacity: 0 !important;
    }
  }
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}
</style>
