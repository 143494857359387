<template>
    <div class="register-page">
        <div class="logo">
            <img src="@/assets/img/logo.png" alt="Logo" width="150">
        </div>
        <div class="register-page-inner">
            <div class="register-form-card">


                <h1>Register</h1>
                <svg class="block mx-auto mb-6" xmlns="http://www.w3.org/2000/svg" width="100" height="2"
                    viewBox="0 0 100 2">
                    <path fill="#D8E3EC" d="M0 0h100v2H0z"></path>
                </svg>

                <a-form class="register-form" ref="formRef" :model="formState" :rules="rules">
                    <a-form-item has-feedback name="name">
                        <a-input v-model:value="formState.name" placeholder="Name" size="large"
                            @keyup.enter="handleSubmit">
                            <template #prefix>
                                <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
                            </template>
                        </a-input>
                    </a-form-item>
                    <a-form-item has-feedback name="email">
                        <a-input v-model:value="formState.email" placeholder="Email" size="large"
                            @keyup.enter="handleSubmit">
                            <template #prefix>
                                <MailOutlined style="color: rgba(0, 0, 0, 0.25)" />
                            </template>
                        </a-input>
                    </a-form-item>
                    <a-form-item has-feedback name="password">
                        <a-input v-model:value="formState.password" type="password" placeholder="Password" size="large"
                            @keyup.enter="handleSubmit">
                            <template #prefix>
                                <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
                            </template>
                        </a-input>
                    </a-form-item>
                    <a-form-item has-feedback name="password_confirmation">
                        <a-input v-model:value="formState.password_confirmation" type="password"
                            placeholder="Password Confirmation" size="large" @keyup.enter="handleSubmit">
                            <template #prefix>
                                <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
                            </template>
                        </a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-button block size="large" :loading="isLoading" type="primary" @click.prevent="handleSubmit">
                            Create Account
                        </a-button>
                    </a-form-item>
                </a-form>
            </div>
            <div class="actions">
                Already have an account? <router-link to="/login">Click here to login.</router-link>
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons-vue';
import { reactive, toRaw } from 'vue';

export default {
    name: 'Register',
    components: { UserOutlined, LockOutlined, MailOutlined },
    data() {
        return {
            formState: reactive({
                name: '',
                email: '',
                password: '',
                password_confirmation: ''
            }),
            rules: {
                'name': [
                    { required: true, message: 'Please input your name' }
                ],
                'email': [
                    { required: true, message: 'Please input your email' },
                    { type: 'email', message: 'Please enter a valid email address' }
                ],
                'password': [
                    { required: true, message: 'Please input your password' }
                ],
                'password_confirmation': [
                    { required: true, message: 'Please input your password confirmation' }
                ]
            }
        }
    },
    computed: {
        ...mapGetters('auth', {
            isLoading: 'isLoading'
        })
    },
    methods: {
        ...mapActions('auth', {
            attemptRegister: 'attemptRegister'
        }),

        handleSubmit() {
            this.$refs['formRef']
                .validate()
                .then(() => {
                    let values = toRaw(this.formState);
                    this.attemptRegister({
                        name: values.name,
                        email: values.email,
                        password: values.password,
                        password_confirmation: values.password_confirmation,
                        to: this.$route.query.to ? this.$route.query.to : '/'
                    });
                })
                .catch(error => {
                    console.log('error', error);
                });
        }
    }
}
</script>
  
<style lang="scss">
.register-page {
    background: rgb(31, 27, 45);
    width: 100%;
    height: 100%;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
        margin-bottom: 10px;
    }

    .register-form-card {
        margin-top: 25px;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
        max-width: 25rem;
        width: 25rem;
        padding: 2rem 2rem 0.4rem 2rem;
        background: #fff;
        border-radius: .5rem;

        img {
            margin-bottom: 20px;
        }

        .register-form {
            margin-top: 30px;
        }

        .register-form-button {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .actions {
        color: #fff;
        margin-top: 20px;
    }
}
</style>
  